.app{
  height: 100vh;
  width: 100vw;
  margin:0;
}
.header{
  height: 45px;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  background: #303031;
  padding: 0 10%;
}
.header p{
  margin: 0;
  user-select: none;
  color: #fcfcfc
}
.name{
  margin-right:30px;
  font-weight: bold;
}
.categories{
  cursor: pointer;
  display: flex;
  height: 100%;
}
.category{
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category:hover{
  background: #2b2b2c;
  height: 100%;
}
.pageActive{
  color: #fcfcfc;
  text-decoration: underline;
}
.pageHidden{
  visibility: hidden;
  height: 0 !important;
  width: 0 !important;
  font-size: 0 !important;
}
.page {
  margin: 20px 0;
  height: calc(100% - 86px);
  padding: 0 10%;
  position: relative;
}
.loader{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: calc(100% - 45px);
  width: 100%;
  flex-direction: column;
  justify-content: center;
  opacity: .5;

}

.loader p{
  z-index: 100;

}
.loader img{
  height: 200px;
  animation: loadingAnimation 3s linear infinite;
}
.react-images__view-image {
  max-height: 100vh !important;
}
@keyframes loadingAnimation {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}
.map{
  height: 100%;
  width: 100%;
}
.instagram{
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.react-photo-gallery--gallery{
  height: 100%;
  width: 100%;
}


@media(max-width: 700px){
  .page{
    padding: 0 10px
  }
  .header{
    padding: 0 10px;
  }
}
@media (max-width: 500px) {
  html{
    overflow: hidden;
  }
  .app{
    height: calc(100vh - 105px)
  }

  .category{
    width: 75px
  }
}